<template>
    <div>
        <v-progress-linear :indeterminate="true" v-if="loading"></v-progress-linear>
        <v-container v-else class="my-2">
            <v-layout row wrap>
                <!-- <v-flex xs12 lg2 xl2 md3 sm4>
                    <v-autocomplete class="mx-1" clearable :items="drivers" v-model="driver_bags.driver_id" dense filled
                        outlined item-text="driver_name" item-value="driver_id" :return-object="false"
                        :label="$store.getters.language.data.drivers.driver_name">
                    </v-autocomplete>
                </v-flex> -->

           
            
                <!-- <v-flex xs12 lg2 xl2 md3 sm4>
                    <v-autocomplete class="mx-1" clearable :items="['out', 'delivered']"
                        v-model="driver_bags.driver_bag_status" dense filled outlined item-text="driver_bag_status"
                        item-value="driver_bag_status" :return-object="false"
                        :label="$store.getters.language.data.driver_bags.driver_bag_status">
                    </v-autocomplete>
                </v-flex> -->

              
                <!-- <v-flex xs12 lg2 xl2 md3 sm4>
                    <v-autocomplete class="mx-1" clearable :items="users" v-model="driver_bags.user_id" dense filled
                        outlined item-text="user_username" item-value="user_id" :return-object="false"
                        :label="$store.getters.language.data.users.user_username">
                    </v-autocomplete>
                </v-flex> -->

            </v-layout>
            <v-layout row wrap mt-5>
                <v-flex xs12>
                    <v-text-field v-model="search" class="ma-3" append-icon="mdi-magnify" label="search" dense outlined
                        hide-details></v-text-field>

                    <v-card>
                        <v-card-text>
                            <v-data-table :headers="headers" v-model="selected_rows" :search="search" :items="rows"
                                class="elevation-0" item-key="driver_bag_id">
                                <template v-slot:[`item.driver_bag_date`]="{ item }">
                                    <div>
                                        {{ new Date(item.driver_bag_date).toISOString().split('T')[0] }} {{ new
                                        Date(item.driver_bag_date).toISOString().split('T')[1].split('.')[0]}}
                                    </div>
                                </template>
                                <template v-slot:[`item.driver_bag_id`]="{ item }">
                                    <div>
                                        <v-btn text :to="'/driver_bags-list/' + item.driver_bag_id" color="teal"
                                            class="mx-1">
                                            <v-icon> mdi-pencil-outline </v-icon> View Bag 
                                        </v-btn>
                                        <!-- <v-btn color="error" icon class="mx-1" @click="selectDriverBags(item)">
                                            <v-icon> mdi-delete-outline </v-icon>
                                        </v-btn> -->
                                    </div>
                                </template>
                            </v-data-table>
                        </v-card-text>

                        

                    </v-card>
                </v-flex>
            </v-layout>
        </v-container>
        
        <v-snackbar v-model="snackbar.value" :color="snackbar.color">
            {{ snackbar.text }}
        </v-snackbar>
    </div>
</template>
<script>
import requests from '../../requests/driver_bags.request.js'
export default {
    data() {
        return {
            driver_bags: {},
            search: '',
            loading: true,
            loading_btn: false,
            selected_rows: [],
            snackbar: {
                value: false,
                text: '',
                color: ''
            },
            rows: [],
            selected_driver_bags: {},
            delete_dialog: false,
            headers: [


                {
                    text: this.$store.getters.language.data.drivers.driver_name,
                    align: 'start',
                    sortable: true,
                    value: 'driver_name',
                },
                {
                    text: this.$store.getters.language.data.drivers.driver_phone,
                    align: 'start',
                    sortable: true,
                    value: 'driver_phone',
                },
                {
                    text: this.$store.getters.language.data.driver_bags.driver_bag_date,
                    align: 'start',
                    sortable: true,
                    value: 'driver_bag_date',
                },
                // {
                //     text: this.$store.getters.language.data.driver_bags.driver_bag_date_delivery,
                //     align: 'start',
                //     sortable: true,
                //     value: 'driver_bag_date_delivery',
                // },
                {
                    text: this.$store.getters.language.data.driver_bags.driver_bag_status,
                    align: 'start',
                    sortable: true,
                    value: 'driver_bag_status',
                },
                {
                    text: this.$store.getters.language.data.driver_bags.driver_bag_total,
                    align: 'start',
                    sortable: true,
                    value: 'driver_bag_total',
                },
                {
                    text: this.$store.getters.language.data.users.user_username,
                    align: 'start',
                    sortable: true,
                    value: 'user_username',
                }, {
                    text: this.$store.getters.language.data.actions,
                    align: 'start',
                    sortable: true,
                    value: 'driver_bag_id',
                }
            ],
        }
    },
    computed: {
        drivers() {
            return this.$store.getters.drivers_list
        },
        users() {
            return this.$store.getters.users_list
        },
    },
    mounted() {
        this.readDriverBags();
    },
    methods: {
        addDriverBags() {
            this.loading_btn = true
            requests.createDriverBags(this.driver_bags).then(r => {
                if (r.status == 200) {
                    this.driver_bags = {}
                    this.rows.push(
                        r.data.new_data
                    )
                    this.snackbar = {
                        value: true,
                        text: 'DriverBags Added',
                        color: 'success'
                    }
                } else {
                    this.snackbar = {
                        value: true,
                        text: 'Fail to add DriverBags',
                        color: 'error'
                    }
                }
            })
                .finally(() => {
                    this.loading_btn = false
                })

        },
        deleteDriverBags(driver_bag_id) {
            requests.deleteDriverBags(driver_bag_id).then(r => {
                this.delete_dialog = false
                if (r.status == 200) {
                    this.rows = this.rows.filter(f => {
                        return f.driver_bag_id != driver_bag_id
                    })
                    this.snackbar = {
                        value: true,
                        text: 'DriverBags Deleted',
                        color: 'success'
                    }
                } else {
                    this.snackbar = {
                        value: true,
                        text: 'Delete Faild',
                        color: 'error'
                    }
                }
            })
        },

        deleteDriverBagsList() {
            let ids = this.selected_rows.map(m => m.driver_bag_id)
            requests.deleteDriverBagsList(ids).then(r => {
                if (r.status == 200) {
                    this.rows = this.rows.filter(f => {
                        return ids.indexOf(f.driver_bag_id) == -1
                    })
                    this.snackbar = {
                        value: true,
                        text: this.selected_rows.length + ' DriverBags Deleted',
                        color: 'success'
                    }
                } else {
                    this.snackbar = {
                        value: true,
                        text: 'Delete Faild',
                        color: 'error'
                    }
                }
            })
        },

        readDriverBags() {
            this.loading = true
            requests.getAllDriverBags().then(r => {
                if (r.status == 200) {
                    this.rows = r.data.rows
                    this.loading = false
                } else {
                    this.snackbar = {
                        value: true,
                        text: 'Fail to read DriverBags',
                        color: 'error'
                    }
                }
            })
                .catch(e => {
                    this.snackbar = {
                        value: true,
                        text: 'Fail to read DriverBags',
                        color: 'error'
                    }
                })
                .finally(() => {
                    this.loading = false
                })
        },
        selectDriverBags(i) {
            this.selected_driver_bags = i
            this.delete_dialog = true
        },
    },
}
</script>